export const PLATFORM_LIST = [
  {
    id: 1,
    value: "neuralpit",
    label: "Neuralpit",
  },
  {
    id: 2,
    value: "acquiretalent",
    label: "Acquiretalent",
  },
  {
    id: 3,
    value: "cvchecker",
    label: "CV Checker",
  },
];
