import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

type Props = {
  children: React.ReactNode;
};

const PrivateRouter: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();

  // effect
  useEffect(() => {
    const currentAccessTokenJSON = localStorage.getItem("access_token");

    if (!currentAccessTokenJSON) {
      navigate("/sign-in");
      return;
    }
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};

export default PrivateRouter;
