import { combineReducers, configureStore } from "@reduxjs/toolkit";

import loadingReducer from "./features/platform";
import platformReducer from "./features/platform";

export const rootReducer = combineReducers({
  loading: loadingReducer,
  platform: platformReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
