import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { cn } from "src/lib/utils";
import { MobileNav } from "./MobileNav";
import { PLATFORM_LIST } from "src/constants";

import Account from "./Account";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/shadcn/ui/select";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { setPlatform } from "src/store/features/platform";

const Header = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const currentPlatform =
    useSelector((state: RootState) => state.platform.currentPlatform) || "";

  // functions
  const handleChangePlatform = (value: string) => {
    dispatch(setPlatform(value));
  };

  return (
    <header className="sticky top-0 z-50 w-full border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="flex h-14 items-center justify-between">
        <div className="flex gap-8 items-center">
          <Link to="/">
            <h1 className="text-4xl font-bold">Acquiretalent.co</h1>
          </Link>
          <nav className="hidden md:flex items-center gap-4 text-sm lg:text-base lg:gap-6">
            <Link
              to="/notifications"
              className={cn(
                "transition-colors hover:text-foreground/80",
                pathname === "/notifications"
                  ? "text-foreground underline font-semibold"
                  : "text-black dark:text-white",
              )}
            >
              Notifications
            </Link>
            <Link
              to="/blogs"
              className={cn(
                "transition-colors hover:text-foreground/80",
                pathname === "/blogs"
                  ? "text-foreground underline font-semibold"
                  : "text-black dark:text-white",
              )}
            >
              Blogs
            </Link>
          </nav>
        </div>
        <div className="flex items-center justify-end gap-1">
          <Select
            value={currentPlatform}
            onValueChange={handleChangePlatform}
          >
            <SelectTrigger className="w-52">
              <SelectValue placeholder="Select a platform" />
            </SelectTrigger>
            <SelectContent className="w-52">
              {PLATFORM_LIST.map((platform) => (
                <SelectItem
                  key={platform.id}
                  value={platform.value}
                >
                  {platform.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Account />
          <MobileNav />
        </div>
      </div>
    </header>
  );
};

export default Header;
