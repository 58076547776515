import { Footer, Header } from "src/layouts";
import { cn } from "src/lib/utils";

type Props = {
  children: React.ReactNode;
};

const MainLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col max-w-screen-2xl mx-auto px-1 md:px-4">
      <Header />
      <main className={cn("flex-1 flex flex-col py-5 md:py-10")}>
        {children}
      </main>

      <Footer />
    </div>
  );
};

export default MainLayout;
