import { z } from "zod";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";

import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { Textarea } from "src/shadcn/ui/textarea";
import { pushNotification } from "src/services/notifications";
import { notificationFormSchema } from "src/models/form-schema";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/shadcn/ui/form";
import { Loader2 } from "lucide-react";
import { toast } from "sonner";

const Notifications = () => {
  const [isLoading, setIsLoading] = useState(false);

  // form
  const form = useForm<z.infer<typeof notificationFormSchema>>({
    resolver: zodResolver(notificationFormSchema),
    defaultValues: {
      title: "",
    },
  });

  // functions
  const handlePushNotification = async (
    data: z.infer<typeof notificationFormSchema>,
  ) => {
    setIsLoading(true);
    const res = await pushNotification(data.title, data.content);
    setIsLoading(false);

    if (res) {
      toast.success("Notification sent successfully");
      form.reset();
    }
  };

  const onSubmit = (data: z.infer<typeof notificationFormSchema>) => {
    handlePushNotification(data);
  };

  return (
    <div className="w-full max-w-[600px] mx-auto">
      <h1 className="text-xl font-semibold text-center mb-8">Notifications</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="space-y-6 mb-8">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter a title"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="content"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Content</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Enter a content"
                      {...field}
                      rows={10}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end">
              <Button
                type="submit"
                disabled={isLoading}
                className="flex items-center gap-2"
              >
                {isLoading && <Loader2 className="size-4 animate-spin" />}
                <span>Push Notification</span>
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default Notifications;
