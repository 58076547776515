import { MainLayout, AuthLayout } from "src/layouts";

import Blogs from "src/pages/blogs";
import HomePage from "src/pages/home";
import LoginPage from "src/pages/sign-in";
import BlogDetail from "src/pages/blog-detail";
import NotificationPage from "src/pages/notifications";

export const PUBLIC_ROUTER = [
  {
    id: "public-1",
    path: "/sign-in",
    page: LoginPage,
    layout: AuthLayout,
  },
];

export const PRIVATE_ROUTER = [
  {
    id: "private-1",
    path: "/",
    page: HomePage,
    layout: MainLayout,
  },
  {
    id: "private-2",
    path: "/notifications",
    page: NotificationPage,
    layout: MainLayout,
  },
  {
    id: "private-3",
    path: "/blogs",
    page: Blogs,
    layout: MainLayout,
  },
  {
    id: "private-4",
    path: "/blogs/:id",
    page: BlogDetail,
    layout: MainLayout,
  },
];
