import { handleGlobalError } from "src/lib/utils";
import api from "../api";

export const pushNotification = async (title: string, content: string) => {
  try {
    const res = await api.post(`/notifications`, {
      title,
      content,
    });
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};
