"use client";

import { Dispatch, SetStateAction, useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "src/shadcn/ui/alert-dialog";
import { Button } from "src/shadcn/ui/button";
import { Loader2 } from "lucide-react";

interface Props {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  onAction?: () => Promise<void>;
}

const AlertActionDialog: React.FC<Props> = ({
  open,
  onOpenChange,
  onAction,
}) => {
  // states
  const [loading, setLoading] = useState(false);

  // functions
  const handleAction = async () => {
    if (onAction) {
      setLoading(true);
      await onAction();
      setLoading(false);
    }
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={() => onOpenChange(false)}
    >
      {/* <AlertDialogTrigger>Open</AlertDialogTrigger> */}
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription className="hidden">
            This action cannot be undone. This will permanently delete your
            account and remove your data from our servers.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="hidden">
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction>Continue</AlertDialogAction>
        </AlertDialogFooter>
        <div className="flex justify-end gap-2">
          <Button
            variant="outline"
            className="hover:bg-gray-200/75"
            onClick={() => onOpenChange(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            className="flex items-center gap-2"
            onClick={handleAction}
          >
            {loading && <Loader2 className="size-4 animate-spin" />}
            <span>Continue</span>
          </Button>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AlertActionDialog;
