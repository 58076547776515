import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

interface PlatformState {
  currentPlatform: string | null;
}

const initialState: PlatformState = {
  currentPlatform: "acquiretalent",
};

export const PlatformSlice = createSlice({
  name: "PlatformStore",
  initialState,
  reducers: {
    setPlatform: (state, action: PayloadAction<string | null>) => {
      state.currentPlatform = action.payload;
    },
  },
});

export const { setPlatform } = PlatformSlice.actions;
export default PlatformSlice.reducer;
