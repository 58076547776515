import { LoginForm } from "src/components/features";

const Login = () => {
  return (
    <div className="w-full flex justify-center items-center gap-4 h-full mx-auto">
      <LoginForm />
    </div>
  );
};

export default Login;
