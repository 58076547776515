import { z } from "zod";
import { toast } from "sonner";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";

import SunEditorCore from "suneditor/src/lib/core";

import { Input } from "src/shadcn/ui/input";
import { IBlog } from "src/models";
import { Button } from "src/shadcn/ui/button";
import { blogFormSchema } from "src/models/form-schema";
import {
  createHrBlog,
  getHrBlogById,
  updateHrBlog,
  deleteHrBlog,
  createNeuralpitBlog,
  updateNeuralpitBlog,
  deleteNeuralpitBlog,
  getNeuralpitBlogById,
} from "src/services/blog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/shadcn/ui/form";

import SunEditor from "suneditor-react";
import AlertActionDialog from "src/components/common/AlertActionDialog";

import "suneditor/dist/css/suneditor.min.css";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";

const BlogDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const currentPlatform = useSelector(
    (state: RootState) => state.platform.currentPlatform,
  );

  // states
  const [blog, setBlog] = useState<IBlog | null>(null);
  const [coverImage, setCoverImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  // refs
  const editorRef = useRef<SunEditorCore | null>(null);

  // form
  const form = useForm<z.infer<typeof blogFormSchema>>({
    resolver: zodResolver(blogFormSchema),
    defaultValues: {
      title: "",
    },
  });

  // functions
  const handleCreateBlog = async (
    title: string,
    cover_pic: string,
    content: string,
  ) => {
    const fnc =
      currentPlatform === "acquiretalent"
        ? createHrBlog
        : currentPlatform === "neuralpit"
        ? createNeuralpitBlog
        : undefined;

    if (!fnc) {
      toast.error("Please select other platform");
      return;
    }

    setIsLoading(true);
    const res = await fnc(title, cover_pic, content);
    setIsLoading(false);

    if (res === "success") {
      toast.success("Blog created successfully");
      navigate("/blogs");
    }
  };

  const handleUpdateBlog = async (title: string, html: string) => {
    if (!id || id === "new" || !blog) return;

    if (!coverImage) {
      toast.error("Please upload a cover image");
      return;
    }

    setIsLoading(true);

    const newBlog: IBlog = {
      id: blog.id,
      title,
      cover_pic: coverImage,
      html,
      crreated_date: blog.crreated_date,
    };

    const fnc =
      currentPlatform === "acquiretalent"
        ? updateHrBlog
        : currentPlatform === "neuralpit"
        ? updateNeuralpitBlog
        : undefined;

    if (!fnc) {
      toast.error("Please select other platform");
      return;
    }

    const res = await fnc(newBlog);
    setIsLoading(false);

    if (res) {
      toast.success("Blog updated successfully");
      navigate("/blogs");
    }
  };

  const onSubmit = (data: z.infer<typeof blogFormSchema>) => {
    if (!coverImage) {
      toast.error("Please upload a cover image");
      return;
    }

    if (editorRef.current && !editorRef.current.getContents(true)) {
      toast.error("Please create content blog");
      return;
    }

    if (editorRef.current) {
      const content = editorRef.current.getContents(false);

      if (id === "new") {
        handleCreateBlog(data.title, coverImage, content);
      } else {
        handleUpdateBlog(data.title, content);
      }
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editorRef.current = sunEditor;
  };

  const getBlog = async () => {
    if (!id || id === "new") return;

    const fnc =
      currentPlatform === "acquiretalent"
        ? getHrBlogById
        : currentPlatform === "neuralpit"
        ? getNeuralpitBlogById
        : undefined;

    if (!fnc) {
      toast.error("Please select other platform");
      return;
    }

    const res = await fnc(id);

    if (res) {
      setBlog({ ...res });
      form.setValue("title", res.title);
      setCoverImage(res.cover_pic);
    }
  };

  const handleDeleteBlog = async () => {
    if (!id || id === "new") return;

    const fnc =
      currentPlatform === "acquiretalent"
        ? deleteHrBlog
        : currentPlatform === "neuralpit"
        ? deleteNeuralpitBlog
        : undefined;

    if (!fnc) {
      toast.error("Please select other platform");
      return;
    }

    const res = await fnc(id);

    if (res === "success") {
      toast.success("Blog deleted successfully");
      navigate("/blogs");
    }
  };

  useEffect(() => {
    getBlog();
  }, [id]);

  return (
    <div className="py-5">
      <div className="flex items-center justify-between">
        <h1 className="py-4 text-2xl font-medium">
          {id && id !== "new" ? "Update Blog" : "Create New"} Blog
        </h1>
        <div className="flex gap-2">
          <Button
            variant="outline"
            onClick={() => {
              navigate("/blogs");
            }}
          >
            Back
          </Button>
          <Button
            variant="destructive"
            disabled={!id || id === "new"}
            onClick={() => setOpenDeleteDialog(true)}
          >
            Delete
          </Button>
        </div>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="space-y-6 mb-8">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter a title"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div>
              <label
                htmlFor="coverPicture"
                className="mb-2 inline-block text-sm font-medium"
              >
                Cover picture
              </label>
              <div className="flex items-center gap-2">
                <input
                  type="file"
                  id="coverPicture"
                  accept="image/*"
                  className="block w-fit text-sm text-gray-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-md file:border-0
                  file:text-sm file:font-semibold
                  file:bg-blue-50 file:text-blue-700
                  hover:file:bg-blue-100"
                  onChange={handleImageUpload}
                />
                {coverImage && (
                  <div className="max-w-96 h-auto object-cover overflow-hidden">
                    <img
                      src={coverImage}
                      alt="Cover preview"
                      className="w-full h-full object-cover"
                    />
                  </div>
                )}
              </div>
            </div>

            <div>
              <label className="mb-2 inline-block text-sm font-medium">
                Content
              </label>
              <SunEditor
                getSunEditorInstance={getSunEditorInstance}
                setContents={blog?.html || ""}
                width="100%"
                height="100%"
                placeholder="Create content blog here!"
                setOptions={{
                  font: [
                    "Arial",
                    "Comic Sans MS",
                    "Courier New",
                    "Impact",
                    "Georgia",
                    "Tahoma",
                    "Trebuchet MS",
                    "Verdana",
                    "Logical",
                    "Salesforce Sans",
                    "Garamond",
                    "Sans-Serif",
                    "Serif",
                    "Times New Roman",
                    "Helvetica",
                  ],
                  fontSize: [
                    8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36, 42,
                    55, 60,
                  ],
                  popupDisplay: "local",

                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize", "formatBlock"],
                    ["textStyle", "fontColor", "hiliteColor", "horizontalRule"],
                    ["bold", "underline", "italic", "strike"],
                    ["subscript", "superscript", "removeFormat", "blockquote"],
                    [
                      "list",
                      "align",
                      "table",
                      "image",
                      "video",
                      "preview",
                      "link",
                    ],
                  ],
                  colorList: [
                    "#ff0000",
                    "#66ff33",
                    "#3333cc",
                    "#ff33cc",
                    "#3333ff",
                    "#666699",
                  ],
                }}
              />
            </div>

            <div className="mt-5  flex justify-end gap-6">
              <Button variant="secondary">Preview</Button>
              <Button
                type="submit"
                disabled={isLoading}
                className="flex items-center gap-2"
              >
                {isLoading && <Loader2 className="animate-spin size-4" />}
                {id && id !== "new" ? "Update" : "Create"}
              </Button>
            </div>
          </div>
        </form>
      </Form>

      {/* <Modal open={openPreviewModal} setOpen={setOpenPreviewModal}>
        <div className="h-[90vh] w-[85vw] overflow-y-auto preview-blog sun-editor-editable">
          <div dangerouslySetInnerHTML={{ __html: `${editorRef.current?.getContents()}` }}></div>
        </div>
      </Modal> */}

      <AlertActionDialog
        open={openDeleteDialog}
        onOpenChange={setOpenDeleteDialog}
        onAction={handleDeleteBlog}
      />
    </div>
  );
};

export default BlogDetail;
