import axios from "axios";

import { handleGlobalError } from "src/lib/utils";
import { IBlog, IBlogResponse } from "src/models";

import api from "../api";

// hr platform
export const createHrBlog = async (
  title: string,
  cover_pic: string,
  html: string,
) => {
  try {
    const res = await api.post("/blogs", {
      title,
      cover_pic,
      html,
    });

    if (res.status === 200) {
      return "success";
    }
    return "failed";
  } catch (error) {
    handleGlobalError(error);
    return "error";
  }
};

export const getHrBlogs = async (
  lastBlogId?: string,
): Promise<IBlogResponse | undefined> => {
  try {
    let url = "/blogs?limit=8";
    if (lastBlogId) {
      url += `&last_blog_id=${lastBlogId}`;
    }

    const res = await api.get(url);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getHrBlogById = async (id: string): Promise<IBlog | undefined> => {
  try {
    const res = await api.get(`/blogs/${id}`);
    return res.data?.blog;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const updateHrBlog = async (blog: IBlog) => {
  const { id, ...data } = blog;
  try {
    const res = await api.patch(`/blogs/${id}`, data);
    return res.data?.blog;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const deleteHrBlog = async (id: string) => {
  try {
    const res = await api.delete(`/blogs/${id}`);

    if (res.status === 200) {
      return "success";
    }
    return "failed";
  } catch (error) {
    handleGlobalError(error);
    return "error";
  }
};

// neuralpit platform
export const createNeuralpitBlog = async (
  title: string,
  cover_pic: string,
  html: string,
) => {
  try {
    const res = await axios.post(
      "https://admin.neuralpit.com/neuralpit/blogs",
      {
        title,
        cover_pic,
        html,
        created_date: new Date().toISOString(),
      },
    );

    if (res.status === 200) {
      return "success";
    }
    return "failed";
  } catch (error) {
    handleGlobalError(error);
    return "error";
  }
};

export const getNeuralpitBlogs = async (
  lastBlogId?: string,
): Promise<IBlogResponse | undefined> => {
  try {
    let url = "https://admin.neuralpit.com/neuralpit/blogs?limit=8";
    if (lastBlogId) {
      url += `&last_blog_id=${lastBlogId}`;
    }

    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getNeuralpitBlogById = async (
  id: string,
): Promise<IBlog | undefined> => {
  try {
    const res = await axios.get(
      `https://admin.neuralpit.com/neuralpit/blogs/${id}`,
    );
    return res.data?.blog;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const updateNeuralpitBlog = async (blog: IBlog) => {
  const { id, ...data } = blog;
  try {
    const res = await axios.patch(
      `https://admin.neuralpit.com/neuralpit/blogs/${id}`,
      data,
    );
    return res.data?.blog;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const deleteNeuralpitBlog = async (id: string) => {
  try {
    const res = await axios.delete(
      `https://admin.neuralpit.com/neuralpit/blogs/${id}`,
    );

    if (res.status === 200) {
      return "success";
    }
    return "failed";
  } catch (error) {
    handleGlobalError(error);
    return "error";
  }
};
