const Footer = () => {
  return (
    <footer className="w-full text-center py-2">
      <p className="text-sm">
        Copyright © NeuralPit Platform {new Date().getFullYear()}
      </p>
    </footer>
  );
};

export default Footer;
